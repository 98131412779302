import { CustomLink } from "../types";

export enum SubLinkId {
  filterSwitching = "1-1",
  liquidManagement = "1-2",
  cellBleeding = "1-3",
  cellHarvesting = "1-4",
  seamlessConnection = "2-1",
  rapidStartup = "2-2",
  singleUserInterface = "2-3",
  perfusionControl = "2-4",
  scalability = "3-1",
  smallFootprint = "3-2",
  multipleApplications = "3-3",
}

export const links: CustomLink[] = [
  {
    id: "1",
    title: "Automation",
    hasSublinks: true,
    subLinks: [
      {
        id: "1-1",
        text: "Filter switching",
        annotation: "Automated Filter Switching",
        animations: ["Filter_R_Anim"],
        changeMaterial: [
          "Xcellerex_Filter_L_Highlight_Material",
          "Xcellerex_Filter_R_Highlight_Material",
        ],
      },
      {
        id: "1-2",
        text: "Liquid management",
        annotation: "Automated Liquid Management",
        animations: ["Bins_Anim"],
        changeMaterial: [
          "Small_Bin_Material",
          "Big_Bin_Material",
          "Bioreactor_Material",
        ],
      },
      {
        id: "1-3",
        text: "Cell bleeding",
        annotation: "Automated Cell Bleeding",
        animations: ["Bins_Anim"],
        changeMaterial: ["Bioreactor_Material"],
      },
      {
        id: "1-4",
        text: "Cell harvesting",
        annotation: "Automated Cell Harvesting",
      },
    ],
  },
  {
    id: "2",
    title: "Efficiency",
    hasSublinks: true,
    subLinks: [
      {
        id: "2-1",
        text: "Seamless connections",
        annotation: "Seamless Connections",
        animations: ["Bins_Anim"],
        changeMaterial: [
          "Xcellerex_Filter_L_Highlight_Material",
          "Xcellerex_Filter_R_Highlight_Material",
          "Bioreactor_Material",
        ],
      },
      {
        id: "2-2",
        text: "Rapid start up",
        annotation: "Quick Single-use Components installation",
        animations: ["Bins_Anim"],
        changeMaterial: [
          "Bioreactor_Material",
          "Small_Bin_Material",
          "Big_Bin_Material",
          "Xcellerex_Hose_Highlight_Matieral",
        ],
      },
      {
        id: "2-3",
        text: "Single user interface",
        annotation: "Single User Interface",
        animations: ["Xstation_Anim"],
        changeMaterial: ["Xstation_Material"],
      },
      {
        id: "2-4",
        text: "Precise perfusion control",
        annotation: "Percise Perfusion Control",
        animations: ["Xstation_Anim"],
        changeMaterial: [
          "Xstation_Material",
          "Xcellerex_Filter_L_Highlight_Material",
          "Xcellerex_Filter_R_Highlight_Material",
        ],
      },
    ],
  },
  {
    id: "3",
    title: "Versatility",
    hasSublinks: true,
    subLinks: [
      {
        id: "3-1",
        text: "Scalability",
      },
      {
        id: "3-2",
        text: "Small footprint",
        annotation: "Scalability",
        changeMaterial: [
          "Xcellerex_Scalability_Width",
          "Xcellerex_Scalability_Height",
        ],
      },
      {
        id: "3-3",
        text: "Multiple applications",
      },
    ],
  },
];
