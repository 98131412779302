export const MODEL_UID = "2d2c9c8fd89b44f3a1436b7b5950649d";

export const cameraMoveTiming = 3.2;

export const resetCameraAnnotation = "ResetCamera";

export const greenEmission = [0, 0.24620132669999983, 0.15896083509999984];
export const grayEmission = [0.1946178304, 0.1946178304, 0.1946178304];

export const filterSwitchingVideo =
  "https://cdn0-70011-odedge0.dna.ip-only.net/70011-odedge0/videos/53d8a0215b90351e830001a5_62690cad6f8d8d6d06000002_streamio-hq-480p.mp4?r=1651052296";
export const liquidManagementVideo =
  "https://cdn0-70011-odedge0.dna.ip-only.net/70011-odedge0/videos/53d8a0215b90351e830001a5_62690cc36f8d8d354800000a_streamio-hq-480p.mp4?r=1651052308";
export const cellBleedingVideo =
  "https://cdn0-70011-odedge0.dna.ip-only.net/70011-odedge0/videos/53d8a0215b90351e830001a5_62690d476f8d8d6d0600000a_streamio-hq-480p.mp4?r=1651052385";
export const cellHarvestingVideo =
  "https://cdn0-70011-odedge0.dna.ip-only.net/70011-odedge0/videos/53d8a0215b90351e830001a5_627246c96f8d8dbc6c000006_streamio-hq-480p.mp4?r=1651656397";
export const seamlessConnectionsVideo =
  "https://cdn0-70011-odedge0.dna.ip-only.net/70011-odedge0/videos/53d8a0215b90351e830001a5_62824ebb6f8d8df220000010_streamio-hq-480p.mp4?r=1655106942";
export const quickSingleUseVideo =
  "https://cdn0-70011-odedge0.dna.ip-only.net/70011-odedge0/videos/53d8a0215b90351e830001a5_62690d016f8d8d4f8a000002_streamio-hq-480p.mp4?r=1651052347";
export const singleUserInterfaceVideo =
  "https://cdn0-70011-odedge0.dna.ip-only.net/70011-odedge0/videos/53d8a0215b90351e830001a5_62690d076f8d8d57ae00000a_streamio-hq-480p.mp4?r=1651052359";
export const precisePerfusionVideo =
  "https://cdn0-70011-odedge0.dna.ip-only.net/70011-odedge0/videos/53d8a0215b90351e830001a5_62a1acc36f8d8d07cd00000c_streamio-hq-480p.mp4?r=1655106978";
