import React, { useEffect } from "react";
import { Title, Spacer, Video } from "../dynamic.components";
import { SidebarVisibility } from "src/types/index.d";
import { useStore } from "src/store";
import {
  attachBinsBioReactorAnimation,
  detachBinsBioReactorAnimation,
} from "src/services/animations";
import { playHotspot } from "src/services/annotations";
import {
  changeMaterialEmissionColor,
  getMaterial,
} from "src/services/materials-textures";
import {
  grayEmission,
  greenEmission,
  liquidManagementVideo,
} from "src/constants";

export const LiquidManagement: React.FC = (): JSX.Element => {
  const { sidebarStatus, animations, apiref, activeSubLink, animationStopped } =
    useStore();
  useEffect(() => {
    if (
      sidebarStatus === SidebarVisibility.show &&
      animationStopped &&
      apiref &&
      animations.length &&
      activeSubLink.animations &&
      activeSubLink.annotation === "Automated Liquid Management"
    ) {
      setTimeout(() => {
        attachBinsBioReactorAnimation();
      }, 1500);
    }

    if (
      sidebarStatus === SidebarVisibility.notShow &&
      !animationStopped &&
      apiref &&
      animations.length &&
      activeSubLink.animations &&
      activeSubLink.annotation === "Automated Liquid Management"
    ) {
      setTimeout(() => {
        detachBinsBioReactorAnimation();
      }, 1500);
    }
  }, [sidebarStatus, animationStopped]);

  const handleAnnotationClick = (annotationName: string) => {
    const smallBinMat = getMaterial(0);
    const bigBinMat = getMaterial(1);
    playHotspot(annotationName);
    if (annotationName === "Small Bin") {
      smallBinMat && changeMaterialEmissionColor(smallBinMat, greenEmission);
      bigBinMat && changeMaterialEmissionColor(bigBinMat, grayEmission);
    }
    if (annotationName === "Large Bin") {
      bigBinMat && changeMaterialEmissionColor(bigBinMat, greenEmission);
      smallBinMat && changeMaterialEmissionColor(smallBinMat, grayEmission);
    }
    if (
      activeSubLink.annotation &&
      annotationName === activeSubLink.annotation
    ) {
      bigBinMat && changeMaterialEmissionColor(bigBinMat, grayEmission);
      smallBinMat && changeMaterialEmissionColor(smallBinMat, grayEmission);
    }
  };

  return (
    <>
      <Title title="Liquid management" />
      <div className="c-sidebar__body">
        <Spacer x={3} />
        <Video src={liquidManagementVideo} />
        <Spacer x={2} />
        <button
          className="button button--primary button--large button--inline"
          onClick={() => handleAnnotationClick("Small Bin")}
          style={{ marginRight: "10px", marginBottom: "10px" }}
        >
          Show small bin
        </button>
        <button
          className="button button--primary button--large button--inline"
          onClick={() => handleAnnotationClick("Large Bin")}
          style={{ marginRight: "10px", marginBottom: "10px" }}
        >
          Show large bin
        </button>
        <button
          className="button button--primary button--large button--inline"
          onClick={() => handleAnnotationClick("Automated Liquid Management")}
        >
          Reset position
        </button>
      </div>
    </>
  );
};
