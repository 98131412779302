import React from "react";
import { resetCameraAnnotation } from "src/constants";
import {
  attachBinsBioReactorAnimation,
  attachBioReactorAndFilterAnimation,
  attachBioReactorAnimation,
  attachXstationAndFilterAnimation,
  detachFilterAnimation,
} from "src/services/animations";
import { playHotspot } from "src/services/annotations";
import { useStore } from "../../store";
import { SidebarVisibility } from "../../types/index.d";
import SidebarContent from "./Sidebar.content.component";

type SidebarProps = {};

const Sidebar: React.FC<SidebarProps> = () => {
  const videoElement: any =
    document.getElementsByClassName("c-cytiva__video")[0];
  const { activeSubLink, sidebarStatus, setSidebarStatus, animationIsPlaying } =
    useStore();
  const closeSidebar = () => {
    setSidebarStatus(SidebarVisibility.notShow);

    if (videoElement) {
      videoElement.pause();
    }
    playHotspot(resetCameraAnnotation);
  };

  const replayAnimation = () => {
    if (activeSubLink.animations && activeSubLink.animations.length) {
      if (activeSubLink.annotation === "Automated Filter Switching") {
        detachFilterAnimation();
      }
      if (activeSubLink.annotation === "Percise Perfusion Control") {
        attachXstationAndFilterAnimation();
      }
      if (activeSubLink.annotation === "Single User Interface") {
        attachXstationAndFilterAnimation();
      }
      if (activeSubLink.annotation === "Automated Liquid Management") {
        attachBinsBioReactorAnimation();
      }
      if (activeSubLink.annotation === "Automated Cell Bleeding") {
        attachBioReactorAnimation();
      }
      if (
        activeSubLink.annotation === "Quick Single-use Components installation"
      ) {
        playHotspot("Quick Single-use Components installation");
        attachBioReactorAnimation();
      }
      if (activeSubLink.annotation === "Seamless Connections") {
        attachBioReactorAndFilterAnimation();
      }
    }
  };

  return (
    <div
      className={`c-sidebar ${
        sidebarStatus === SidebarVisibility.show
          ? "show-sidebar-motion"
          : sidebarStatus === SidebarVisibility.notShow && "hide-sidebar-motion"
      }`}
    >
      <div className="c-sidebar__content">
        <button
          className="button button--secondary button--xlarge button--thick-border"
          onClick={closeSidebar}
        >
          Return to 3D overview
        </button>
        <SidebarContent />
        {activeSubLink.animations && activeSubLink.animations.length && (
          <button
            className={`button button--secondary button--xlarge button--thick-border ${
              animationIsPlaying ? "button--disabled" : ""
            }`}
            onClick={replayAnimation}
            disabled={animationIsPlaying}
          >
            Replay animation
          </button>
        )}
      </div>
    </div>
  );
};

export default Sidebar;
