import React from "react";
import { useStore } from "src/store";
import { CustomLink, Spacer } from "../sidebar/contents/dynamic.components";

const TradeMark = ({}) => {
  const { closeOverlay } = useStore();
  return (
    <>
      <div
        className="c-cytiva__trademark-section"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="c-cytiva__trademark-top">
          <img
            className="c-cytiva__trademark-top__logo"
            src="favicon.ico"
            width="40"
            alt="Cytiva logo"
          />
          <button
            className="c-cytiva__trademark-top__close"
            onClick={closeOverlay}
          >
            X
          </button>
        </div>
        <p className="c-cytiva__trademark-text">
          Cytiva and the Drop logo are trademarks of Life Sciences IP Holdings
          Corporation or an affiliate doing business as Cytiva. <br />
          ReadyMate, ReadyToProcess, and Xcellerex are trademarks of Global Life
          Sciences Solutions USA LLC or an affiliate doing business as Cytiva.
          <Spacer />
          The Xcellerex APS (Automated Perfusion System) system is designed for
          perfusion cell culturing and may therefore be used for a broad variety
          of cell culture processes (e.g. in the field of intensified and/or
          continuous cell culturing). <br />
          Purchaser shall at all times be solely responsible for obtaining any
          additional intellectual property rights necessary for the use of the
          Xcellerex APS system for their specific cell culture processes.
          <Spacer />
          ReadyMate: this product is covered by US patent number 6,679,529 B2
          owned by Johnson &amp; Boley Holdings, LLC and licensed to Cytiva.
          <Spacer />
          Combics is a trademark of Sartorius Industrial Scales GmbH &amp; Co.
          <br />
          Levitronix is a trademark of Levitronix GmbH.
          <br />
          Microsoft and Windows are registered trademarks of Microsoft
          Corporation.
          <br /> PROFIBUS is a trademark of PROFIBUS Nutzerorganization e.V.
          <br />
          Wonderware is a trademark of AVEVA Software.
          <br /> Any other third-party trademarks are the property of their
          respective owners.
          <Spacer />
          © 2022 Cytiva.
          <Spacer />
          For local office contact information, visit:{" "}
          <a
            className="c-cytiva__trademark-text"
            style={{ textDecoration: "none" }}
            href="https://cytiva.com/contact"
            target="_blank"
          >
            cytiva.com/contact
          </a>
        </p>
      </div>
    </>
  );
};

export default TradeMark;
