import React from "react";
import { Title, Text, Subtitle, Spacer, Image } from "../dynamic.components";
import scalability_image from "src/assets/images/scalability.jpg";
// import cell_clarification_image from "src/assets/images/cell_clarification.jpg";

export const Scalability: React.FC = (): JSX.Element => {
  return (
    <>
      <Title title="Scalability" />
      <div className="c-sidebar__body">
        <Subtitle subtitle="Scalability from < 50 L to 500 L with the flexibility to use a wide range of both microfilters and ultrafilters specific to your application." />
        <Image
          images={[
            {
              src: scalability_image,
              alt: "Scalability image",
              customWidth: 100,
            },
          ]}
        />
        <Subtitle subtitle="Support your volume needs" />
        <Spacer />
        <Text text="Designed to address your needs for scalability Xcellerex&trade; APS integrates seamlessly with three bioreactors in the Xcellerex&trade; XDR family (50, 200, and 500 L) to support your volume needs from < 50 L to 500 L." />
        <Spacer />
        <Text text="The Xcellerex&trade; XDR bioreactor product line offers the benefits of single-use technology and stirred-tank design in a modular turnkey platform. XDR units are designed to deliver scalable performance in batch, fed-batch, and perfusion good manufacturing practices (GMP) operations." />
        <Spacer />

        <Subtitle subtitle="Support your filtration needs" />
        <Spacer />
        <Text text="Up to two filters can be installed on the system at one time, and it accommodates multiple pore sizes (UF and MF), path lengths (30 and 60 cm), and membrane areas (0.12 to 4.4 m2). This allows use of Xcellerex&trade; APS in many different perfusion applications." />
        <Spacer />
        {/* <Image
          images={[
            {
              src: cell_clarification_image,
              alt: "Scalability image",
              customWidth: 100,
            },
          ]}
        /> */}
      </div>
    </>
  );
};
