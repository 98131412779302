import React, { FC } from "react";

export const Text: FC<{ text: string }> = ({ text }) => {
  return <p className="c-sidebar__text">{text}</p>;
};

export const Title: FC<{ title: string }> = ({ title }) => {
  return <h2 className="c-sidebar__title">{title}</h2>;
};

export const Subtitle: FC<{ subtitle: string }> = ({ subtitle }) => {
  return <h2 className="c-sidebar__subtitle">{subtitle}</h2>;
};

export const Image: FC<{
  images: { src: any; alt?: string; customWidth?: number }[];
}> = ({ images }) => {
  return (
    <div className="c-sidebar__image-container">
      {images.map((image) => (
        <img
          style={{ width: image.customWidth ? `${image.customWidth}%` : "" }}
          key={image.alt}
          className="c-sidebar__image-img"
          src={image.src}
          alt={image.alt ? image.alt : ""}
        />
      ))}
    </div>
  );
};

export const List: FC<{ listArray: string[] }> = ({ listArray }) => {
  return (
    <ul className="c-sidebar__list">
      {listArray.map((list, index) => (
        <li key={index} className="c-sidebar__list-item c-sidebar__text">
          {list}
        </li>
      ))}
    </ul>
  );
};

export const CustomLink: FC<{ href: string; text?: string }> = ({
  href,
  text = "",
}) => {
  return (
    <a
      className="button--link c-sidebar__link"
      href={href}
      style={text ? { textDecoration: "none" } : {}}
      target="_blank"
      rel="noreferrer noopener"
    >
      <p>{text ? text : href}</p>
    </a>
  );
};

export const Video: FC<{ src: string }> = ({ src }) => {
  return (
    <div>
      <video className="c-cytiva__video" controls style={{ width: "100%" }}>
        <source src={src} type="video/mp4" />
      </video>
    </div>
  );
};

export const Spacer: FC<{ x?: number }> = ({ x = 1 }) => (
  <span style={{ padding: `${x * 5}px`, display: "block" }}></span>
);
