import React, { useEffect } from "react";
import { Title, Spacer, Video } from "../dynamic.components";
import { useStore } from "src/store";
import { SidebarVisibility } from "src/types/index.d";
import {
  attachXstationAndFilterAnimation,
  detachXstationAndFilterAnimation,
} from "src/services/animations";
import { singleUserInterfaceVideo } from "src/constants";

export const SingleUserInterface: React.FC = (): JSX.Element => {
  const { sidebarStatus, animations, apiref, activeSubLink, animationStopped } =
    useStore();
  useEffect(() => {
    if (
      sidebarStatus === SidebarVisibility.show &&
      animationStopped &&
      apiref &&
      animations.length &&
      activeSubLink.animations &&
      activeSubLink.annotation === "Single User Interface"
    ) {
      setTimeout(() => {
        attachXstationAndFilterAnimation();
      }, 1500);
    }

    if (
      sidebarStatus === SidebarVisibility.notShow &&
      !animationStopped &&
      apiref &&
      animations.length &&
      activeSubLink.animations &&
      activeSubLink.annotation === "Single User Interface"
    ) {
      setTimeout(() => {
        detachXstationAndFilterAnimation();
      }, 1500);
    }
  }, [sidebarStatus, animationStopped]);
  return (
    <>
      <Title title="Single user interface" />
      <div className="c-sidebar__body">
        <Spacer x={3} />
        <Video src={singleUserInterfaceVideo} />
      </div>
    </>
  );
};
