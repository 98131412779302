import React, { useEffect } from "react";
import { cellBleedingVideo } from "src/constants";
import {
  attachBioReactorAnimation,
  detachBioReactorAnimation,
} from "src/services/animations";
import { useStore } from "src/store";
import { SidebarVisibility } from "src/types/index.d";
import { Title, Spacer, Video } from "../dynamic.components";

export const CellBleeding: React.FC = (): JSX.Element => {
  const { sidebarStatus, animations, apiref, activeSubLink, animationStopped } =
    useStore();

  useEffect(() => {
    if (
      sidebarStatus === SidebarVisibility.show &&
      animationStopped &&
      apiref &&
      animations.length &&
      activeSubLink.animations &&
      activeSubLink.annotation === "Automated Cell Bleeding"
    ) {
      setTimeout(() => {
        attachBioReactorAnimation();
      }, 1500);
    }

    if (
      sidebarStatus === SidebarVisibility.notShow &&
      !animationStopped &&
      apiref &&
      animations.length &&
      activeSubLink.animations &&
      activeSubLink.annotation === "Automated Cell Bleeding"
    ) {
      setTimeout(() => {
        detachBioReactorAnimation();
      }, 1500);
    }
  }, [sidebarStatus, animationStopped]);

  return (
    <>
      <Title title="Cell bleeding" />
      <div className="c-sidebar__body">
        <Spacer x={3} />
        <Video src={cellBleedingVideo} />
      </div>
    </>
  );
};
