import { grayEmission, greenEmission } from "src/constants";
import { useStore } from "../store";
import { Animation, Material } from "../types";
import { playHotspot } from "./annotations";
import {
  getMaterial,
  changeMaterialOpacity,
  changeMaterialVisibility,
  changeMaterialEmissionColor,
  changeMaterialColorOpacity,
} from "./materials-textures";

export const storeAnimations = () => {
  const api = useStore.getState().apiref;
  api.getAnimations((err: any, animations: Animation[]) => {
    if (!err) {
      useStore.setState({ animations: animations });
    }
  });
};

export const getAnimation = (index: number) => {
  const { activeSubLink, animations } = useStore.getState();
  return animations.find((animation) => {
    if (activeSubLink.animations) {
      return animation[1] === activeSubLink.animations[index];
    }
  });
};

export const playAnimation = (animationName: string) => {
  const { apiref, activeSubLink, materials } = useStore.getState();
  const animation = useStore
    .getState()
    .animations.find((animation) => animation[1] === animationName);
  const animationId = animation && animation[0];
  const animationTime = animation && animation[2] * 1000;

  apiref.seekTo(0);
  apiref.play(() => {
    useStore.setState({ animationIsPlaying: true });
  });

  apiref.setCurrentAnimationByUID(animationId, function (err: any) {
    if (!err) {
      if (activeSubLink.changeMaterial) {
        const slectedMaterial = materials.find((mat: Material) => {
          if (activeSubLink.changeMaterial) {
            mat.name === activeSubLink.changeMaterial[0];
          }
        });
        if (slectedMaterial) {
          changeMaterialVisibility(slectedMaterial, true);
        }
      }
    }
  });

  setTimeout(() => {
    apiref.pause(() => {
      useStore.setState({ animationIsPlaying: false });
    });
    if (
      activeSubLink.changeMaterial &&
      activeSubLink.animations &&
      activeSubLink.animations.length > 1 &&
      activeSubLink.animations[1] === animationName
    ) {
      const slectedMaterial = materials.find((mat: Material) => {
        if (activeSubLink.changeMaterial) {
          mat.name === activeSubLink.changeMaterial[0];
        }
      });
      if (slectedMaterial) {
        changeMaterialVisibility(slectedMaterial, false);
      }
    }
  }, animationTime);
};

export const detachFilterAnimation = () => {
  const { apiref, setAnimationStopped } = useStore.getState();
  const detachTime = 1.4;
  setAnimationStopped(false);
  //Animations
  const animationFilter = getAnimation(0);
  //Materials
  const leftFilterMat = getMaterial(0);
  const rightFilterMat = getMaterial(1);

  const animationFilterId = animationFilter && animationFilter[0];

  apiref.setCurrentAnimationByUID(animationFilterId);

  apiref.seekTo(0, () => {
    apiref.play(() => {
      useStore.setState({ animationIsPlaying: true });
      if (leftFilterMat && rightFilterMat) {
        changeMaterialOpacity(leftFilterMat, 0);
        changeMaterialOpacity(rightFilterMat, 0);
      }
      let opacity = 0;
      const intervals = 100;
      const subscriber =
        leftFilterMat &&
        rightFilterMat &&
        setInterval(() => {
          opacity += intervals / (detachTime * 1200);
          changeMaterialOpacity(leftFilterMat, opacity);
          changeMaterialOpacity(rightFilterMat, opacity);
        }, intervals);

      setTimeout(() => {
        apiref.pause(() => {
          if (leftFilterMat && rightFilterMat) {
            changeMaterialOpacity(leftFilterMat, 1);
            changeMaterialOpacity(rightFilterMat, 1);
          }
          useStore.setState({ animationIsPlaying: false });
          subscriber && clearInterval(subscriber);
        });
      }, detachTime * 1000);
    });
  });
};

export const attachFilterAnimation = () => {
  const { apiref, setAnimationStopped } = useStore.getState();
  const detachTime = 1.4;
  const attachTime = 1.1;
  const leftFilterMat = getMaterial(0);
  const rightFilterMat = getMaterial(1);

  let opacity = 1;
  const internals = 100;

  apiref.seekTo(detachTime, () => {
    apiref.play(() => {
      const subscriber = setInterval(() => {
        opacity -= internals / (attachTime * 1000);
        if (leftFilterMat && rightFilterMat) {
          changeMaterialOpacity(leftFilterMat, opacity);
          changeMaterialOpacity(rightFilterMat, opacity);
        }
      }, internals);
      setTimeout(() => {
        apiref.pause(() => {
          if (leftFilterMat && rightFilterMat) {
            changeMaterialOpacity(leftFilterMat, 0);
            changeMaterialOpacity(rightFilterMat, 0);
          }
        });
        clearInterval(subscriber);
        setAnimationStopped(true);
      }, attachTime * 1000);
    });
  });
};

export const attachXstationAndFilterAnimation = () => {
  const { apiref, setAnimationStopped } = useStore.getState();
  const attachTime = 1.6;
  setAnimationStopped(false);
  //Animations
  const animationXstation = getAnimation(0);
  //Materials
  const xStationMat = getMaterial(0);
  const leftFilterMat = getMaterial(1);
  const rightFilterMat = getMaterial(2);

  const animationXstationId = animationXstation && animationXstation[0];
  apiref.setCurrentAnimationByUID(animationXstationId);

  apiref.seekTo(0, () => {
    apiref.play(() => {
      useStore.setState({ animationIsPlaying: true });
      if (leftFilterMat && rightFilterMat) {
        changeMaterialOpacity(leftFilterMat, 0);
        changeMaterialOpacity(rightFilterMat, 0);
      }
      if (xStationMat) {
        changeMaterialOpacity(xStationMat, 1);
      }
      let opacity = 0;
      const intervals = 100;
      const subscriber =
        leftFilterMat &&
        rightFilterMat &&
        setInterval(() => {
          opacity += intervals / (attachTime * 1000);
          changeMaterialOpacity(leftFilterMat, opacity);
          changeMaterialOpacity(rightFilterMat, opacity);
        }, intervals);

      setTimeout(() => {
        if (leftFilterMat && rightFilterMat) {
          changeMaterialOpacity(leftFilterMat, 1);
          changeMaterialOpacity(rightFilterMat, 1);
        }
        apiref.pause(() => {
          useStore.setState({ animationIsPlaying: false });
          subscriber && clearInterval(subscriber);
        });
      }, attachTime * 1000);
    });
  });
};

export const detachXstationAndFilterAnimation = () => {
  const { apiref, setAnimationStopped } = useStore.getState();
  const attachXstationTime = 1.6;
  const detachXstationTime = 1.31;

  const xStationMat = getMaterial(0);
  const leftFilterMat = getMaterial(1);
  const rightFilterMat = getMaterial(2);

  let opacity = 1;
  const intervals = 100;

  apiref.seekTo(attachXstationTime, () => {
    apiref.play(() => {
      const subscriber =
        leftFilterMat &&
        rightFilterMat &&
        setInterval(() => {
          opacity -= intervals / (detachXstationTime * 1200);
          if (leftFilterMat && rightFilterMat) {
            changeMaterialOpacity(leftFilterMat, opacity);
            changeMaterialOpacity(rightFilterMat, opacity);
          }
        }, intervals);
      setTimeout(() => {
        apiref.pause(() => {
          if (leftFilterMat && rightFilterMat) {
            changeMaterialOpacity(leftFilterMat, 0);
            changeMaterialOpacity(rightFilterMat, 0);
          }
          if (xStationMat) {
            changeMaterialOpacity(xStationMat, 0);
          }
        });
        subscriber && clearInterval(subscriber);
        setAnimationStopped(true);
      }, detachXstationTime * 1000);
    });
  });
};

export const attachBinsBioReactorAnimation = () => {
  const { apiref, setAnimationStopped } = useStore.getState();
  setAnimationStopped(false);
  const moveInTime = 1.8;

  const smallBinMat = getMaterial(0);
  const bigBinMat = getMaterial(1);
  const bioReactorMat = getMaterial(2);

  const binsBioReactorAnimation = getAnimation(0);

  const binsBioReactorAnimationId =
    binsBioReactorAnimation && binsBioReactorAnimation[0];

  apiref.setCurrentAnimationByUID(binsBioReactorAnimationId);
  apiref.seekTo(0, () => {
    apiref.play(() => {
      useStore.setState({ animationIsPlaying: true });
      if (smallBinMat && bigBinMat && bioReactorMat) {
        changeMaterialEmissionColor(smallBinMat, grayEmission);
        changeMaterialEmissionColor(bigBinMat, grayEmission);
        changeMaterialEmissionColor(bioReactorMat, grayEmission);
      }

      setTimeout(() => {
        apiref.pause(() => {
          useStore.setState({ animationIsPlaying: false });
        });
      }, moveInTime * 1000);
    });
  });
};

export const detachBinsBioReactorAnimation = () => {
  const { apiref, setAnimationStopped } = useStore.getState();
  const moveInTime = 1.8;
  const binsBioAnimation = getAnimation(0);
  const binsAnimationTime = binsBioAnimation && binsBioAnimation[2];
  const moveOutTime = binsAnimationTime - moveInTime;

  const smallBinMat = getMaterial(0);
  const bigBinMat = getMaterial(1);
  const bioReactorMat = getMaterial(2);

  apiref.seekTo(moveInTime, () => {
    apiref.play(() => {
      let opacity = 1;
      const intervals = 100;
      const subscriber =
        bioReactorMat &&
        setInterval(() => {
          opacity -= intervals / (moveOutTime * 1100);
          changeMaterialOpacity(bioReactorMat, opacity);
        }, intervals);
      setTimeout(() => {
        apiref.pause(() => {
          if (smallBinMat && bigBinMat && bioReactorMat) {
            changeMaterialOpacity(smallBinMat, 0);
            changeMaterialOpacity(bigBinMat, 0);
            changeMaterialOpacity(bioReactorMat, 0);
          }
        });
        subscriber && clearInterval(subscriber);
        setAnimationStopped(true);
      }, moveOutTime * 1000);
    });
  });
};

export const attachBioReactorAnimation = () => {
  const { apiref, setAnimationStopped, activeSubLink } = useStore.getState();
  setAnimationStopped(false);
  const moveInTime = 1.8;

  const bioReactorMat = getMaterial(0);
  const hoseTubeMat = getMaterial(3);

  const bioReactorAnimation = getAnimation(0);

  const bioReactorAnimationId = bioReactorAnimation && bioReactorAnimation[0];
  apiref.setCurrentAnimationByUID(bioReactorAnimationId, (err: any) => {
    if (!err) {
      apiref.seekTo(0, () => {
        apiref.play(() => {
          useStore.setState({ animationIsPlaying: true });
          if (bioReactorMat) {
            changeMaterialEmissionColor(bioReactorMat, greenEmission);
          }
          hoseTubeMat && changeMaterialOpacity(hoseTubeMat, 0);

          setTimeout(() => {
            hoseTubeMat && changeMaterialOpacity(hoseTubeMat, 1);
            apiref.pause(() => {
              useStore.setState({ animationIsPlaying: false });
            });
          }, moveInTime * 1000);
          if (
            activeSubLink.annotation ===
            "Quick Single-use Components installation"
          ) {
            setTimeout(() => {
              playHotspot("Automated Cell Harvesting");
            }, 2500);
          }
        });
      });
    }
  });
};

export const detachBioReactorAnimation = () => {
  const { apiref, setAnimationStopped } = useStore.getState();
  const moveInTime = 1.8;

  const bioReactorAnimation = getAnimation(0);
  const bioReactorAnimationTime = bioReactorAnimation && bioReactorAnimation[2];
  const moveOutTime = bioReactorAnimationTime - moveInTime;

  const bioReactorMat = getMaterial(0);
  const hoseTubeMat = getMaterial(3);

  apiref.seekTo(moveInTime, () => {
    apiref.play(() => {
      const interval = 150;
      let opacity = 1;
      const subscriber =
        bioReactorMat &&
        setInterval(() => {
          opacity -= interval / (moveOutTime * 1000);
          changeMaterialColorOpacity(bioReactorMat, opacity, greenEmission);
          hoseTubeMat && changeMaterialOpacity(hoseTubeMat, opacity);
        }, interval);
      setTimeout(() => {
        apiref.pause(() => {
          subscriber && clearInterval(subscriber);
          if (bioReactorMat) {
            changeMaterialOpacity(bioReactorMat, 0);
            hoseTubeMat && changeMaterialOpacity(hoseTubeMat, 0);
          }
        });
        setAnimationStopped(true);
      }, moveOutTime * 1000);
    });
  });
};

export const attachBioReactorAndFilterAnimation = () => {
  const { apiref, setAnimationStopped } = useStore.getState();
  const attachTime = 1.7;
  setAnimationStopped(false);
  //Animations
  const bioReactorAnimation = getAnimation(0);
  //Materials
  const leftFilterMat = getMaterial(0);
  const rightFilterMat = getMaterial(1);
  const bioReactorMat = getMaterial(2);

  const bioReactorAnimationId = bioReactorAnimation && bioReactorAnimation[0];
  apiref.setCurrentAnimationByUID(bioReactorAnimationId);

  apiref.seekTo(0, () => {
    apiref.play(() => {
      useStore.setState({ animationIsPlaying: true });
      if (leftFilterMat && rightFilterMat && bioReactorMat) {
        changeMaterialOpacity(leftFilterMat, 0);
        changeMaterialOpacity(rightFilterMat, 0);
        changeMaterialEmissionColor(bioReactorMat, greenEmission);
      }
      let opacity = 0;
      const interval = 100;
      const subscriber =
        leftFilterMat &&
        rightFilterMat &&
        setInterval(() => {
          opacity += interval / (attachTime * 1200);
          changeMaterialOpacity(leftFilterMat, opacity);
          changeMaterialOpacity(rightFilterMat, opacity);
        }, interval);

      setTimeout(() => {
        if (leftFilterMat && rightFilterMat && bioReactorMat) {
          changeMaterialOpacity(leftFilterMat, 1);
          changeMaterialOpacity(rightFilterMat, 1);
        }
        apiref.pause(() => {
          useStore.setState({ animationIsPlaying: false });
          subscriber && clearInterval(subscriber);
        });
      }, attachTime * 1000);
    });
  });
};

export const detachBioReactorAndFilterAnimation = () => {
  const { apiref, setAnimationStopped } = useStore.getState();
  const attachBioReactorTime = 1.7;
  const bioReactorAnimation = getAnimation(0);
  const bioReactorAnimationTime = bioReactorAnimation && bioReactorAnimation[2];
  const detachXstationTime = bioReactorAnimationTime - attachBioReactorTime;

  const leftFilterMat = getMaterial(0);
  const rightFilterMat = getMaterial(1);
  const bioReactorMat = getMaterial(2);

  let opacity = 1;
  const internals = 150;

  apiref.seekTo(attachBioReactorTime, () => {
    apiref.play(() => {
      const subscriber = setInterval(() => {
        opacity -= internals / (detachXstationTime * 1000);
        if (leftFilterMat && rightFilterMat && bioReactorMat) {
          changeMaterialOpacity(leftFilterMat, opacity);
          changeMaterialOpacity(rightFilterMat, opacity);
          changeMaterialColorOpacity(bioReactorMat, opacity, greenEmission);
        }
      }, internals);
      setTimeout(() => {
        apiref.pause(() => {
          if (leftFilterMat && rightFilterMat && bioReactorMat) {
            changeMaterialOpacity(leftFilterMat, 0);
            changeMaterialOpacity(rightFilterMat, 0);
            changeMaterialOpacity(bioReactorMat, 0);
          }
        });
        clearInterval(subscriber);
        setAnimationStopped(true);
      }, detachXstationTime * 1000);
    });
  });
};
