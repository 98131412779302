import React from "react";
import { cellHarvestingVideo } from "src/constants";
import { Title, Video, Spacer } from "../dynamic.components";

export const CellHarvesting: React.FC = () => {
  return (
    <>
      <Title title="Cell harvesting" />
      <div className="c-sidebar__body">
        <Spacer x={3} />
        <Video src={cellHarvestingVideo} />
      </div>
    </>
  );
};
