import React, { FC } from "react";
import { useStore } from "../../store";
import { SubLinkId } from "../../data";
import { FilterSwitching } from "./contents/automation/Filter-switching";
import { LiquidManagement } from "./contents/automation/Liquid-management";
import { CellBleeding } from "./contents/automation/Cell-bleeding";
import { CellHarvesting } from "./contents/automation/Cell-harvesting";
import { SeamlessConnections } from "./contents/efficiency/Seamless-connections";
import { PerfusionControl } from "./contents/efficiency/Perfusion-control";
import { RapidStartup } from "./contents/efficiency/Rapid-start-up";
import { SingleUserInterface } from "./contents/efficiency/Single-user-interface";
import { Scalability } from "./contents/versatility/Scalability";
import { SmallFootprint } from "./contents/versatility/Small-footprint";
import { MultipleApplications } from "./contents/versatility/Multiple-applications";

const Content: FC = (): JSX.Element => {
  const { activeSubLink } = useStore();
  switch (activeSubLink.id) {
    case SubLinkId.filterSwitching:
      return <FilterSwitching />;
    case SubLinkId.liquidManagement:
      return <LiquidManagement />;
    case SubLinkId.cellBleeding:
      return <CellBleeding />;
    case SubLinkId.cellHarvesting:
      return <CellHarvesting />;
    case SubLinkId.seamlessConnection:
      return <SeamlessConnections />;
    case SubLinkId.rapidStartup:
      return <RapidStartup />;
    case SubLinkId.singleUserInterface:
      return <SingleUserInterface />;
    case SubLinkId.perfusionControl:
      return <PerfusionControl />;
    case SubLinkId.scalability:
      return <Scalability />;
    case SubLinkId.smallFootprint:
      return <SmallFootprint />;
    case SubLinkId.multipleApplications:
      return <MultipleApplications />;
    default:
      return <></>;
  }
};

const SidebarContent: FC = (): JSX.Element => {
  return (
    <div className="c-sidebar__wrapper">
      <Content />
    </div>
  );
};

export default SidebarContent;
