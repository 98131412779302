import React, { useState } from "react";
import {
  Title,
  Image,
  Text,
  CustomLink,
  Spacer,
  Subtitle,
} from "../dynamic.components";
import multiple_connection_1_1 from "src/assets/images/multiple_connection_1_1.jpg";
import multiple_connection_1_2 from "src/assets/images/multiple_connection_1_2.jpg";
import multiple_connection_2_1 from "src/assets/images/multiple_connection_2_1.jpg";
import multiple_connection_2_2 from "src/assets/images/multiple_connection_2_2.jpg";
import multiple_connection_3_1 from "src/assets/images/multiple_connection_3_1.jpg";
import multiple_connection_3_2 from "src/assets/images/multiple_connection_3_2.jpg";
import multiple_connection_4_1 from "src/assets/images/multiple_connection_4_1.jpg";
import multiple_connection_4_2 from "src/assets/images/multiple_connection_4_2.jpg";

export const MultipleApplications: React.FC = (): JSX.Element => {
  const [activeSection, setActiveSection] = useState<number>(1);
  return (
    <>
      <Title title="Multiple applications" />
      <div className="c-sidebar__body">
        <Text text="Xcellerex&trade; APS supports a variety of intensified upstream operations. You can enter your own process data in the Process Intensifier app to see if an intensification approach will give you an advantage over your current process." />
        <Spacer />
        <button className="button button--medium button--secondary">
          <a
            href="https://www.cytivalifesciences.com/en/us/solutions/bioprocessing/products-and-solutions/process-intensification/process-intensifier-app?extcmp=cy22164-global-bp-aps-2022-parent-ms-ms-aps-explorer"
            style={{
              textDecoration: "none",
              lineHeight: "23px",
            }}
            target="_blank"
          >
            See how Xcellerex™ APS can intensify your upstream operations
          </a>
        </button>
        <Spacer x={2} />

        <div className="c-sidebar__body-multiple-connections__navbar">
          <button
            className={`button button--secondary button--fixwidth button--medium button--inline ${
              activeSection === 1 ? "button--hover" : ""
            }`}
            onClick={() => setActiveSection(1)}
          >
            High-seed fed-batch
          </button>
          <button
            className={`button button--secondary button--fixwidth button--medium button--inline ${
              activeSection === 2 ? "button--hover" : ""
            }`}
            onClick={() => setActiveSection(2)}
          >
            Steady-state perfusion
          </button>
          <button
            className={`button button--secondary button--fixwidth button--medium button--inline ${
              activeSection === 3 ? "button--hover" : ""
            }`}
            onClick={() => setActiveSection(3)}
          >
            Hybrid perfusion fed-batch
          </button>
          <button
            className={`button button--secondary button--fixwidth button--medium button--inline ${
              activeSection === 4 ? "button--hover" : ""
            }`}
            onClick={() => setActiveSection(4)}
          >
            Concentrated fed-batch
          </button>
        </div>
        {activeSection === 1 && (
          <>
            <Subtitle subtitle="High-seed fed-batch" />
            <Spacer x={2} />
            <Text
              text="How does it work? Seeding the fed-batch production bioreactor at a higher cell density shifts the early
                growth phase — where the titer is low — to the smaller, less costly N-1 seed culture reactor. This shortens
                the production duration but keeps titers the same. The high-seed strategy can remove bottlenecks in
                production chains and increase the volumetric yield. The result? Intensification of the fed-batch
                biomanufacturing process."
            />
            <Image
              images={[
                {
                  src: multiple_connection_1_1,
                  alt: "High-seed fed-batch table image",
                },
                {
                  src: multiple_connection_1_2,
                  alt: "High-seed fed-batch graph image",
                },
              ]}
            />
            <Subtitle subtitle="Table 1: Illustration of time gain in the production bioreactor at different inoculation cell densities (VCD)" />
          </>
        )}

        {activeSection === 2 && (
          <>
            <Subtitle subtitle="Steady-state perfusion" />
            <Spacer x={2} />
            <Text
              text="How does it work? Steady-state perfusion processes let you run a continuous operation over extended
          periods of time — by constantly providing fresh nutrients for the cells while also removing spent media and
          waste products. The key benefits of perfusion processes are compact process design, flexibility, consistent
          product quality, and increased productivity and yield compared with batch and fed-batch processes."
            />
            <Image
              images={[
                {
                  src: multiple_connection_2_1,
                  alt: "Steady-state perfusion image",
                },
                {
                  src: multiple_connection_2_2,
                  alt: "Steady-state perfusion graph image",
                },
              ]}
            />
          </>
        )}
        {activeSection === 3 && (
          <>
            <Subtitle subtitle="Hybrid perfusion fed-batch" />
            <Spacer x={2} />
            <Text
              text="How does it work? A hybrid perfusion fed-batch process typically involves an initial perfusion operation to
          increase the VCD to 50–150 MVC/mL. Then, the operation is switched to fed-batch, and the product is
          retained in the bioreactor. The result? Typically a 3- to 4-fold increase in titer compared to a fed-batch
          culture."
            />
            <Image
              images={[
                {
                  src: multiple_connection_3_1,
                  alt: "Hybrid perfusion fed-batch image",
                },
                {
                  src: multiple_connection_3_2,
                  alt: "Hybrid perfusion fed-batch graph image",
                },
              ]}
            />
          </>
        )}
        {activeSection === 4 && (
          <>
            <Subtitle subtitle="Concentrated fed-batch" />
            <Spacer x={2} />
            <Text
              text="How does it work? Nutrients are continuously added to a bioreactor, and an ultra-filter is used to remove
          inhibitory byproducts. The product is retained in the bioreactor. The result? A VCD of 100–200 MVC/mL is
          achieved. Typically, a 2.5- to 6-fold increase in titer is possible compared to a fed-batch process."
            />
            <Image
              images={[
                {
                  src: multiple_connection_4_1,
                  alt: "Concentrated fed-batch image",
                },
                {
                  src: multiple_connection_4_2,
                  alt: "Concentrated fed-batch graph image",
                },
              ]}
            />
          </>
        )}
      </div>
    </>
  );
};
