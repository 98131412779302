import React, { useEffect } from "react";
import { Text, Title, Image, Spacer, List } from "../dynamic.components";
import { useStore } from "src/store";
import { SidebarVisibility } from "src/types/index.d";
import { showScale, hideScale } from "src/services/materials-textures";
// import smallFootprint_image from "src/assets/images/small_foot_print.jpg";

export const SmallFootprint: React.FC = (): JSX.Element => {
  const { sidebarStatus, animations, apiref, activeSubLink, animationStopped } =
    useStore();
  useEffect(() => {
    if (
      sidebarStatus === SidebarVisibility.show &&
      animationStopped &&
      apiref &&
      animations.length &&
      activeSubLink.annotation === "Scalability"
    ) {
      setTimeout(() => {
        showScale();
      }, 1500);
    }

    if (
      sidebarStatus === SidebarVisibility.notShow &&
      !animationStopped &&
      apiref &&
      animations.length &&
      activeSubLink.annotation === "Scalability"
    ) {
      setTimeout(() => {
        hideScale();
      }, 200);
    }
  });

  return (
    <>
      <Title title="Small footprint" />
      <div className="c-sidebar__body">
        <Spacer x={2} />
        <Text text="Compact, fully integrated perfusion operation" />
        <List
          listArray={[
            "Accurate: use floor scales to provide gravimetric control",
            "Continuous operation: perfusate can be fed directly to the protein A step",
          ]}
        />
        {/* <Image
          images={[
            {
              src: smallFootprint_image,
              alt: "Small Footprint image",
              customWidth: 100,
            },
          ]}
        /> */}
      </div>
    </>
  );
};
